<template>
  <div id="contact">
    <v-container fluid>
      <v-row dense>
        <v-col align="center">
          <h1>Contact Us</h1>
        </v-col>
      </v-row>
      <v-form
        ref="form"
        v-model="form.valid"
        lazy-validation
      >
        <v-row>
          <v-col sm="6" v-if="false">
            <v-row>
              <v-col>
                <v-row dense>
                  <v-col>
                    <v-text-field
                      filled
                      label="Name"
                      v-model="form.fields.name"
                      placeholder="Please enter your full name"
                      :rules="form.validation.nameRules"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col>
                    <v-text-field
                      filled
                      label="Email"
                      v-model="form.fields.email"
                      placeholder="name@domain.com"
                      :rules="form.validation.emailRules"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col>
                    <v-textarea
                      filled
                      label="Message"
                      v-model="form.fields.message"
                      placeholder="Message"
                      :rules="form.validation.messageRules"
                      required
                    ></v-textarea>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col sm="12" align="right">
                    <v-btn color="primary" dark x-large @click.prevent="contact">
                      Send
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
          <v-col sm="6" offset-sm="3" align="center">
            <pp-shades>
              <p><b><h2 class="white--text">Licensing, Integrations, and Sales:</h2></b><br><b>sales@parlaypoker.com</b></p>
              <p><b><h2 class="white--text">General Information:</h2></b><br><b>info@parlaypoker.com</b></p>
            </pp-shades>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'Contact.vue',
  data(){
    return{
      form: {
        resendValidation: false,
        valid: true,
        validation:{
          nameRules: [
            v => !!v || 'Name is required',
          ],
          emailRules: [
            v => !!v || 'E-mail is required',
            v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
          ],
          messageRules: [
            v => !!v || 'Message is required',
          ],
        },
        fields:{
          name: "",
          email: "",
          message: "",
        },
      },
    }
  },
  methods: {
    errorFor(field) {
      return this.errors != null && this.errors[field] ? this.errors[field] : null;
    },
    contact() {
      if(this.$refs.form.validate()){
        // let self = this;
        
      }
    },
  },

}
</script>

<style lang="scss" scoped>
  #contact{
    
  }
</style>
